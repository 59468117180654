<script context="module" lang="ts">
  import {
    Sortable,
    MultiDrag,
    AutoScroll,
    OnDrag,
  } from "sortablejs/modular/sortable.core.esm.mjs";
  Sortable.mount(new MultiDrag(), new AutoScroll(), new OnDrag());
</script>

<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import type ISortable from "sortablejs";
  import type { SortableOptions } from "sortablejs";
  const dispatch = createEventDispatcher();

  // every item is an object with a unique ID for identification
  type Item = $$Generic<{ id: number | null }>;
  export let items: Item[] = [];
  export let idKey = "id"; // id attribute changeable if needed
  export let ulClass = "";
  export let liClass = "";
  export let getItemById: ((id: string) => Item | undefined) | null = null;
  export let sortableOptions: SortableOptions & {
    multiDrag?: boolean;
    multiDragKey?: string;
    selectedClass?: string;
    delaySkipClass?: string;
    onDrag?: Function;
    onSelect?: Function;
    onDeselect?: Function;
  } = {};
  export let sortable: (ISortable & { multiDrag: any }) | null = null;

  sortableOptions = Object.assign({}, sortableOptions); // prevent leak to outside
  sortableOptions.store = sortableOptions.store || {
    get: (sortable) => [], // placeholder
    set: (sortable) => [], // placeholder
  };
  if (sortableOptions.group && !getItemById) {
    throw new Error(
      "When using group, please provide a function called `getItemById` (as a prop) that gives an item in case it gets dropped from somewhere else. Otherwise, the SortableList cannot know what the item is exactly."
    );
  }

  getItemById ??= (id) => {
    return items.find((item) => item[idKey] == id); // should only loosely check as IDs are auto-converted to strings
  };

  const _store_set = sortableOptions.store.set;
  sortableOptions.store.set = (sortable) => {
    const newItems = sortable
      .toArray()
      .map(getItemById!)
      .filter((i) => i !== undefined);
    _store_set(sortable); // still call old set callback function
    dispatch("orderChanged", { items: newItems });
  };

  let listElement: HTMLElement;

  function makeSortable() {
    // @ts-ignore
    sortableOptions.deselectTarget = document.getElementById("list");
    sortable = Sortable.create(listElement, sortableOptions);

    // fix for https://github.com/SortableJS/Sortable/issues/2012
    // HACK
    let shouldPropagate = false;
    function startMove(e: Event) {
      shouldPropagate = true;
    }

    listElement.addEventListener("touchstart", startMove);
    listElement.addEventListener("pointerdown", startMove);
    listElement.addEventListener("mousedown", startMove);
    const dragProto = Object.getPrototypeOf(sortable!.multiDrag);
    const oldDrop = dragProto.drop;
    sortable!.multiDrag.drop = (ev: unknown) => {
      if (!shouldPropagate && !Sortable.ghost) {
        return;
      }
      oldDrop.bind(sortable!.multiDrag)(ev);
    };
    // @ts-ignore
    const oldDisable = sortable._disableDelayedDragEvents.bind(sortable);
    // @ts-ignore
    sortable._disableDelayedDragEvents = function () {
      oldDisable();
      shouldPropagate = false;
    };
  }

  $: if (listElement && !sortable) makeSortable();
  $: for (const item of items) {
    if (!item || item[idKey] === undefined) {
      throw new Error("Item " + item + " has no valid identifier " + idKey);
    }
  }

  // async function fixSelect(node) {
  //   await tick();
  //   if(selected.find(t => t.id == node.dataset.id))
  //     Sortable.utils.select(node);
  //   return {
  //     destroy() {
  //       if(node.classList.contains("selected"))
  //         Sortable.utils.deselect(node, listElement);
  //     }
  //   }
  // }

  export function deselectAll() {
    sortable?.multiDrag._deselectMultiDrag();
  }

  onDestroy(() => {
    sortable?.destroy();
  });
</script>

<ul bind:this={listElement} class={ulClass}>
  {#each items as item, index (item[idKey])}
    <li
      class={liClass}
      data-id={"" + item[idKey]}
      data-index={index}
      id={sortableOptions.group + "-" + item[idKey]}
    >
      <slot {item} {index} />
    </li>
  {/each}
</ul>
